import React, { useState, useEffect, useRef } from "react";
import styles from "./Filters.module.scss";
import {
  useRefreshOrgCustomFields,
  useSearchCurrOrgsFields,
} from "api/resources/organization/organization";

import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import Button from "components/Button/Button";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Label } from "components/layouts/Label/Label";
import EditSingleCustomField from "components/CustomFieldsEditor/EditSingle";
import { randomColor } from "components/tables/EditableTable/utils";
import { Loading } from "components/Loading/Loading";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";

function Filters({
  chosenFilters,
  setChosenFilters,
  chosenColumns,
  setChosenColumns,
  saveAudience,
  saveText,
  audience,
  external,
  open,
  orgId,
  setShowDynamic,
  columnsOnly,
}) {
  const [activeFilters, setActiveFilters] = useState(
    chosenFilters ? chosenFilters?.length : 0
  );

  const [change, setChange] = useState(false);
  const [show, setShow] = useState(audience || open);
  const [newFilter, setNew] = useState(false);
  const [tab, setTab] = useState(columnsOnly ? 1 : 0);
  const searchCustomFields = useSearchCurrOrgsFields("");
  const title = audience ? "Dynamic Filters" : "Filters";

  function changeFilter(field, index) {
    let temp = [...chosenFilters];
    if (index >= 0) {
      temp[index] = field;
      let i = chosenFilters.findIndex((f) => f?.id === field?.id);

      setActiveFilters(
        activeFilters +
          (field?.properties?.length - chosenFilters[i]?.properties?.length)
      );
    } else {
      temp.push(field);
      setActiveFilters(activeFilters + field?.properties?.length);
    }
    setChange(true);
    setChosenFilters(temp);
  }

  function removeFilter(index) {
    let temp = [...chosenFilters];

    setActiveFilters(activeFilters - temp[index]?.properties?.length);
    if (index >= 0) {
      temp.splice(index, 1);
      setChosenFilters(temp);
    }
    setChange(true);
  }

  return (
    <div
      className={styles.filter}
      style={{
        maxWidth: !show ? "60px" : "",
        alignItems: !show ? "center" : "",
        cursor: !show ? "pointer" : "",
        gap: "0em",
        minWidth: !show ? "60px" : "300px",
      }}
      onClick={() => (!show ? setShow(true) : undefined)}
    >
      {show && (
        <div
          className={styles.filterHeader}
          style={{
            gap: ".5em",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            className={styles.filterHeader}
            style={{
              gap: ".5em",
              // justifyContent: show ? "space-between" : "",
              width: "100%",
            }}
          >
            {!columnsOnly && (
              <div
                className={`${styles.filterHeaderItem} ${
                  tab === 0 && styles.active
                }`}
                onClick={() => setTab(0)}
                style={{ justifyContent: setShowDynamic ? "flex-start" : "" }}
              >
                <i className="bi-funnel"></i>

                <span>
                  {" "}
                  {setShowDynamic && !audience && "Temporary "}
                  {title}
                </span>
                <div className={audience ? styles.number2 : styles.number}>
                  {activeFilters}
                </div>
                {audience && (
                  <Label
                    labelIcon={<i className="bi-info-circle"></i>}
                    style={{ width: "fit-content" }}
                    tooltipText={
                      "Contacts that fit the criteria of these filters will be part of your audience along with those you imported in other ways"
                    }
                    tooltipStyle={{ right: "0px" }}
                  ></Label>
                )}
              </div>
            )}
            {((!audience && !setShowDynamic) || columnsOnly) && (
              <div
                className={`${styles.filterHeaderItem} ${
                  tab === 1 && styles.active
                }`}
                onClick={() => setTab(1)}
                style={{ justifyContent: setShowDynamic ? "flex-start" : "" }}
              >
                <i className="bi-layout-text-window-reverse"></i>

                <span>Columns</span>
              </div>
            )}
            {show && (
              <i
                className={`bi-chevron-left ${styles.close}`}
                onClick={() =>
                  !setShowDynamic ? setShow(false) : setShowDynamic(false)
                }
              ></i>
            )}
          </div>
        </div>
      )}

      <div
        className={styles.filterHeader}
        style={{
          gap: "1.5em",
          justifyContent: show ? "space-between" : "",
          flexDirection: !show ? "column" : "",
        }}
      >
        {!show && (
          <div
            className={styles.filterHeader}
            style={{
              flexDirection: !show ? "column" : "",
              gap: !show ? "1.5em" : "",
              // height: !show ? "200px" : "",
              // border: "2px solid black"
            }}
          >
            {!show && (
              <div className={audience ? styles.number2 : styles.number}>
                {activeFilters}
              </div>
            )}
            <div
              className={styles.filterHeader}
              style={{
                gap: !show ? ".7em" : ".5em",
                flexDirection: !show ? "column" : "",
              }}
            >
              {show && <i className="bi-funnel"></i>}

              <span
                style={{
                  transform: !show ? "rotate(-90deg)" : "",
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: audience ? "100px" : "",
                }}
              >
                {title}
              </span>
              {!show && (
                <i
                  className="bi-funnel"
                  style={{ transform: "rotate(-90deg)" }}
                ></i>
              )}
            </div>
            {show && (
              <div className={audience ? styles.number2 : styles.number}>
                {activeFilters}
              </div>
            )}
          </div>
        )}
        {(!show && !audience && !setShowDynamic) && (
          <div
            className={styles.filterHeader}
            style={{
              gap: !show ? ".9em" : ".5em",
              flexDirection: !show ? "column" : "",
            }}
          >
            <span
              style={{
                transform: !show ? "rotate(-90deg)" : "",
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
                height: "50px",
              }}
            >
              Columns
            </span>
            {!show && (
              <i
                className="bi-layout-text-window-reverse"
                style={{ transform: "rotate(-90deg)" }}
              ></i>
            )}
          </div>
        )}
      </div>
      {show && tab === 0 && (
        <div className={styles.fade}>
          <div
            className={styles.link}
            style={{ marginBottom: "-10px", paddingTop: "15px" }}
          >
            <a
              onClick={() => {
                setChosenFilters([]);
                setActiveFilters(0);
              }}
            >
              {" "}
              Clear All
            </a>
          </div>

          <TheFilter
            field={{
              name: "new filter",
              displayName: "New Filter",
              properties: ["none"],
              values: ["none"],
              column: "New Filter",
            }}
            name={"New Filter"}
            // onCheck={(val) =>
            //   checkAll(field, val, fetchCustomFields.data.getCurrOrgFields)
            // }
            changeFilter={changeFilter}
            chosenFilter={chosenFilters}
            // externalFilter={externalFilter}
            visible={newFilter}
            setVisible={() => setNew(!newFilter)}
            removeFilter={removeFilter}
            columns={chosenColumns}
            setColumns={setChosenColumns}
          ></TheFilter>
          {audience && change && (
            <div>
              <Button
                shadow
                onClick={() => {
                  saveAudience();
                  setChange(false);
                }}
              >
                {saveText ? saveText : "Save Audience"}
              </Button>
            </div>
          )}
        </div>
      )}
      {show && tab === 1 && searchCustomFields.isSuccess && (
        <div className={styles.fade}>
          <TheColumns
            columns={chosenColumns}
            setColumns={setChosenColumns}
            customFields={searchCustomFields.data.fields}
            external={external}
            orgId={orgId}
            // field={{
            //   name: "new filter",
            //   displayName: "New Filter",
            //   properties: ["none"],
            // }}
            // name={"New Filter"}
            // // onCheck={(val) =>
            // //   checkAll(field, val, fetchCustomFields.data.getCurrOrgFields)
            // // }
            // changeFilter={changeFilter}
            // chosenFilter={chosenFilters}
            // // externalFilter={externalFilter}
            // visible={newFilter}
            // setVisible={() => setNew(!newFilter)}
            // removeFilter={removeFilter}
          ></TheColumns>
        </div>
      )}

      {/* {show && tab === 0 && (
        <div className={styles.fade}>
          {fetchCustomFields.isSuccess &&
            fetchCustomFields.data.getCurrOrgFields.map((field, i) => (
              <React.Fragment key={i}>
                {field?.filterable && (
                  <TheFilter
                    key={i}
                    field={field}
                    name={field?.displayName}
                    onCheck={(val) =>
                      checkAll(
                        field,
                        val,
                        fetchCustomFields.data.getCurrOrgFields
                      )
                    }
                    changeFilter={changeFilter}
                    chosenFilter={chosenFilters}
                    // externalFilter={externalFilter}
                    visible={active === field.id}
                    setVisible={setActive}
                  ></TheFilter>
                )}
              </React.Fragment>
            ))}
        </div>
      )} */}
    </div>
  );
}

export default Filters;

const availableColumns = [
  {
    id: "contactName",
    value: "contactName",
    name: "Contact Name",
    accessor: "firstName",
    label: "Contact Name",
    // width: 100,
    dataType: "text",
    enabled: true,
    sort: false,
    canSort: true,
    fullName: true,
    cell_style: (name, id, i, contact) =>
      contact && (
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          {" "}
          <div
            className={styles.profileImage}
            style={{
              backgroundColor: contact?.color ? contact?.color : randomColor(),
            }}
          >
            {" "}
            <span>
              {contact && contact?.firstName[0] + contact?.lastName[0]}
            </span>
          </div>
          {contact && `${contact?.firstName} ${contact?.lastName}`}
        </div>
      ),
  },
  {
    id: "firstName",
    value: "firstName",
    name: "First Name",
    accessor: "firstName",
    label: "First Name",
    // width: 100,
    dataType: "text",
    enabled: true,
    sort: false,
    canSort: true,
    editable: true,
  },
  {
    id: "lastName",
    value: "lastName",
    name: "Last Name",
    accessor: "lastName",
    label: "Last Name",
    // width: 100,
    dataType: "text",
    enabled: true,
    sort: false,
    canSort: true,
    editable: true,
  },
  {
    id: "email",
    value: "email",
    name: "Email",
    accessor: "email",
    label: "Email",
    // width: 250,
    dataType: "text",
    enabled: true,
    sort: true,
    canSort: true,
    cell_style: null,
    editable: true,
  },
  {
    id: "updatedAt",
    value: "updatedAt",
    name: "Last Modified",
    accessor: "updatedAt",
    label: "Last Modified",
    // width: 100,
    dataType: "text",
    enabled: true,
    sort: false,
    canSort: true,
    cell_style: null,
  },
  {
    id: "createdAt",
    value: "createdAt",
    name: "When Added",
    accessor: "createdAt",
    label: "When Added",
    dataType: "text",
    enabled: true,
    sort: false,
    canSort: true,
    cell_style: null,
  },
  {
    id: "unsubscribed",
    value: "unsubscribed",
    name: "Unsubscribed",
    accessor: "unsubscribed",
    label: "Unsubscribed",
    dataType: "text",
    enabled: true,
    sort: true,
    canSort: true,
    editable: true,
    cell_style: (item) => <div>{item ? "True" : "False"}</div>,
  },
  {
    id: "participationCount",
    value: "participationCount",
    name: "Survey Invites",
    label: "Survey Invites",
    dataType: "text",
    accessor: "participationCount",
    enabled: true,
    sort: true,
    canSort: true,
    cell_style: null,
  },
];

export function TheColumns({
  columns,
  setColumns,
  customFields,
  external,
  orgId,
}) {
  // const [hiddenCols, setHiddenCols] = useState(initHiddenColumns());
  const [gripped, setGripped] = useState(false);
  const [active, setActive] = useState(false);
  const [create, setCreate] = useState(false);

  function hideColumn(index) {
    let tempcols = [...columns];
    tempcols[index].enabled = !tempcols[index].enabled;
    setColumns(tempcols);
  }
  function showColumn(val, add, i) {
    if (!add) {
      let tempcols = [...val];
      setColumns(tempcols);
    } else {
      let tempcols = [...columns];
      tempcols[i].enabled = !tempcols[i]?.enabled;
      setColumns(tempcols);
    }
  }

  function initHiddenColumns() {
    let cols = [...availableColumns];
    for (let field of customFields) {
      cols.push({ ...field, value: field?.id, label: field?.displayName });
    }
    return cols;
  }

  // useEffect(() => {
  //   setHiddenCols(initHiddenColumns());
  // }, [columns]);

  // function handleDragEnd({ active, over }) {
  //   if (!over?.id) {
  //     return;
  //   }
  //   if (over.id === active.id) {
  //     return;
  //   }

  //   switchPages(active.id, over.id);
  // }
  function handleDragOver({ active, over }) {
    if (active.id !== over?.id) {
      let indexOfActive = columns.findIndex((f) => f.id === active.id);
      let indexOfOver = columns.findIndex((f) => f.id === over.id);
      let newOrder = [...columns];
      newOrder.splice(indexOfActive, 1, columns[indexOfOver]);
      newOrder.splice(indexOfOver, 1, columns[indexOfActive]);
      setColumns(newOrder);
    }
  }

  const refreshFields = useRefreshOrgCustomFields();
  const [scanning, setScanning] = useState(false);

  function refreshCustomFields() {
    if (!scanning) {
      setScanning(true);
      refreshFields.mutate(
        {
          orgId: orgId,
        },
        {
          onSuccess: () => {
            // console.log(data);
            setScanning(false);
          },
        }
      );
    }
  }

  return (
    <>
      {active && (
        <EditSingleCustomField
          field={active}
          onClose={() => setActive(null)}
        ></EditSingleCustomField>
      )}

      {create && (
        <EditSingleCustomField
          brandNew
          onClose={() => setCreate(null)}
        ></EditSingleCustomField>
      )}

      <div
        className={`${styles.filterHeader} ${gripped && styles.gripped}`}
        style={{
          gap: ".5em",
          // justifyContent: "space-between",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <CombinedInput
          options={columns}
          value={columns.map((c) => {
            if (c.enabled) {
              return c;
            }
          })}
          onChange={(val, i) => showColumn(val, true, i)}
          selectMultiple
          hideChosen
          nohover
          style={{
            margin: "10px 0px",
            borderRadius: ".5em",
            backgroundColor: "white",
            border: "2px solid #d8d9d9",
          }}
          placeholder={"Search Column..."}
          searchIcon
        />
        {external && (
          <span className={styles.tip}>
            Columns with blue checks are being shown in the table and will be
            added on import
          </span>
        )}
        {/* <span>Visible Columns</span> */}
        {/* {columns &&
        columns.length > 0 &&
        columns.map((col, i) => (
          <div className={styles.col} onClick={() => hideColumn(i)}>
            {col.name} <div className={styles.show}>Hide</div>
          </div>
        ))} */}
        {!external && (
          <span className={styles.link}>
            <a onClick={() => setCreate(true)}>Create Column</a>
            <a
              onClick={() => {
                !scanning ? refreshCustomFields(true) : undefined;
              }}
            >
              Refresh Columns{" "}
              {scanning && <Loading height={20} width={20}></Loading>}
            </a>
          </span>
        )}
        {columns && columns.length > 0 && (
          <DndContext
            // sensors={sensors}
            collisionDetection={closestCenter}
            onDragOver={handleDragOver}
            onDragEnd={() => setGripped(false)}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext
              // id={"Sortable Pages"}
              items={columns}
              strategy={verticalListSortingStrategy}
            >
              {columns.map((col, i) => (
                <Column
                  key={i}
                  col={col}
                  i={i}
                  hideColumn={hideColumn}
                  gripped={gripped}
                  setGripped={setGripped}
                  setActive={setActive}
                  used={col?.enabled ? i : -1}
                  showColumn={showColumn}
                ></Column>
              ))}
            </SortableContext>
          </DndContext>
        )}
      </div>
    </>
  );
}

function Column({
  col,
  i,
  hideColumn,
  gripped,
  setGripped,
  setActive,
  used,
  showColumn,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: col.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? 10 : 0,
    // backgroundColor: highlight ? "#efefef" : show ? "#F9F9F9" : undefined,
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      style={{ ...style }}
      className={`${styles.col} ${gripped && styles.gripped}`}
    >
      {col.name}
      <div className={styles.filterHeader} style={{ gap: "0em" }}>
        {used >= 0 && (
          <i
            className={`bi-x-lg ${styles.show}`}
            onClick={() => hideColumn(i)}
          ></i>
        )}
        {used < 0 && (
          <div
            className={`${styles.show2}`}
            onClick={() => showColumn(col, true, i)}
          >
            Add
          </div>
        )}
        {col?.custom && (
          <i
            className={`bi-pencil ${styles.show2}`}
            onClick={() => setActive(col)}
          ></i>
        )}
        {used >= 0 && <i className={`bi-check ${styles.show3}`}></i>}
        <i
          {...listeners}
          className={`bi-grip-vertical ${gripped ? style.grab : styles.grip} ${
            gripped && styles.gripped
          }`}
          onMouseDown={() => setGripped(true)}
        ></i>
      </div>
    </div>
  );
}

export const TheFilter = ({
  changeFilter,
  field,
  chosenFilter,
  removeFilter,
  columns,
  setColumns,
}) => {
  const [searchString, setSearchString] = useState("");
  const [view, setView] = useState("");
  const [newFilter, setNewFilter] = useState();
  const [activeFilter, setActiveFilter] = useState();
  const [newFilterField, setNewFilterField] = useState();
  const searchCustomFields = useSearchCurrOrgsFields(searchString);
  const [addColumn, setAddColumn] = useState(false);

  useEffect(() => {
    searchCustomFields.refetch();
  }, [searchString]);

  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      if (!view) {
        setView(false);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef(null);

  function handleApply(filter, index) {
    if (newFilter) {
      changeFilter(newFilter, index);
      setNewFilter(null);
      if (addColumn) {
        let tempcols = [...columns];
        index = tempcols.findIndex((c) => c?.id === newFilter?.id);
        tempcols[index].enabled = addColumn;
        setColumns(tempcols);
      }
    } else {
      changeFilter(activeFilter, index);
      setActiveFilter(null);
    }
  }

  return (
    <div key={field.name} className={`${styles.accordian}`}>
      <CombinedInput
        options={columns}
        value={[]}
        onChange={(val, i, field) => {
          // debugger;
          let newfield = {
            operator: 0,
            name: field.objectAccessor
              ? field?.accessor + "." + field.objectAccessor
              : field?.accessor,
            properties: field?.properties ? field?.properties : [],
            id: field?.id,
            label: field?.name,
            column: field?.sfName,
            values: field?.values ? field?.values : [],
          };
          setNewFilter({
            operator: 0,
            name: field.objectAccessor
              ? field?.accessor + "." + field.objectAccessor
              : field?.accessor,
            properties: [],
            id: field?.id,
            label: field?.name,
            column: field?.sfName,
            values: [],
          });
          setNewFilterField(newfield);
          setView(false);
        }}
        dropdownonly
        selectMultiple
        hideChosen
        nohover
        style={{
          margin: "10px 0px",
          borderRadius: ".5em",
          backgroundColor: "white",
          border: "2px solid #d8d9d9",
        }}
        placeholder={"Search Column..."}
        searchIcon
      />
      {chosenFilter &&
        chosenFilter.length > 0 &&
        searchCustomFields.isSuccess &&
        chosenFilter?.map((filter, i) => (
          <SingleFilter
            key={i}
            filter={filter}
            field={
              columns?.find((f) => f?.id === filter?.id)
                ? columns?.find((f) => f?.id === filter?.id)
                : filter
            }
            index={i}
            // setNewFilter={setNewFilter}
            // newFilter
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            handleApply={handleApply}
            handleRemove={removeFilter}
          ></SingleFilter>
        ))}

      {newFilter && (
        <SingleFilter
          filter={newFilter}
          field={newFilterField}
          setNewFilter={setNewFilter}
          newFilter
          handleApply={handleApply}
          handleRemove={removeFilter}
          index={-1}
          used={columns.find((c) => c?.id === newFilter?.id)?.enabled}
          setAddColumn={setAddColumn}
          addColumn={addColumn}
        ></SingleFilter>
      )}
    </div>
  );
};

const operators = [
  { value: 0, label: "equals" },
  { value: 1, label: "does not equal" },
  { value: 2, label: "contains" },
  { value: 3, label: "does not contain" },
  // { value: 4, label: "less than" },
  // { value: 5, label: "greater than" },
  // { value: 6, label: "starts with" },
  // { value: 7, label: "ends with" },
];

function SingleFilter({
  filter,
  field,
  setNewFilter,
  newFilter,
  handleApply,
  setActiveFilter,
  handleRemove,
  index,
  used,
  setAddColumn,
  addColumn,
}) {
  const [view, setView] = useState(newFilter);

  function handleChangeOperator(op) {
    let temp = { ...filter };
    temp.operator = op.value;
    if (newFilter) {
      setNewFilter(temp);
    } else {
      setActiveFilter(temp);
    }
  }

  function handleChangeProp(props, index) {
    let temp = { ...filter };
    temp.properties = props;
    temp.values = props.map((p) => {
      if (typeof p === "string") {
        return p;
      } else {
        return p.value;
      }
    });
    if (newFilter) {
      setNewFilter(temp);
    } else {
      setActiveFilter(temp);
    }
  }

  function handleCancel() {
    if (newFilter) {
      setNewFilter(null);
    } else {
      setActiveFilter(null);
    }
    setView(false);
  }

  return (
    <div
      className={styles.newFilter}
      onClick={() => {
        if (!view) {
          setView(true);
          setActiveFilter(filter);
        }
      }}
      style={{
        cursor: !view ? "pointer" : "",
        gap: view ? "1em" : "",
        paddingBottom: view ? "1em" : "",
      }}
    >
      <div className={styles.header}>
        <div style={{ display: "flex", gap: ".5em", alignItems: "center" }}>
          {field?.label}{" "}
          {!view && (
            <div className={styles.operator}>
              ({operators.find((o) => o.value === filter.operator)?.label})
            </div>
          )}
        </div>
        {!view && <i className={`bi-plus ${styles.plus}`}></i>}
        {view && (
          <i
            className={`bi-trash ${styles.trash}`}
            style={{ fontSize: "1.2em", padding: "2px 5px" }}
            onClick={(e) => {
              e.stopPropagation();
              if (newFilter) {
                handleCancel();
              } else {
                handleRemove(index);
                setView(false);
              }
            }}
          ></i>
        )}
      </div>
      {!view && (
        <CombinedInput
          selectMultiple
          search
          value={filter?.properties}
          options={field?.properties}
          onChange={handleChangeProp}
          style={{ backgroundColor: "#EAEFF2" }}
          chosenStyle={{ backgroundColor: "#B5E1DF" }}
          // iconReplacement={
          //   <i
          //     className={`bi-trash ${styles.trash}`}
          //     onClick={(e) => {
          //       e.stopPropagation();
          //       if (newFilter) {
          //         handleCancel();
          //       } else {
          //         handleRemove(index);
          //       }
          //     }}
          //   ></i>
          // }
        />
      )}

      {view && (
        <div className={styles.filterSettings}>
          <CombinedInput
            select
            label={"Operator"}
            value={operators.find((op) => op.value === filter?.operator)}
            options={operators}
            style={{ backgroundColor: "white", borderRadius: ".5em" }}
            onChange={handleChangeOperator}
            icon={"bi-chevron-down"}
          />
          {/* <div style={{height: "fit-content", width: "100%", display: "flex", flexDirection: 'column', gap: "20px"}}> */}
          <CombinedInput
            selectMultiple
            search
            label={"Value"}
            value={filter?.properties}
            options={field?.properties}
            style={{ backgroundColor: "white", borderRadius: ".5em" }}
            onChange={handleChangeProp}
            icon={"bi-chevron-down"}
            placeholder="Search..."
            pushDropdown
            canCreate
          />
          {/* </div> */}
          {newFilter && !used && (
            <div className={styles.buttons}>
              <Checkbox
                checked={addColumn}
                onChange={(e) => setAddColumn(e.target.checked)}
              ></Checkbox>{" "}
              <div className={styles.header} style={{ width: "fit-content" }}>
                Show Column
              </div>
            </div>
          )}
          <div className={styles.buttons}>
            <Button
              shadow
              seafoam
              style={{
                padding: "3px 20px",
                fontSize: ".9em",
                height: "fit-content",
              }}
              onClick={() => handleCancel()}
              link
            >
              Cancel
            </Button>
            <Button
              shadow
              style={{
                padding: "3px 20px",
                fontSize: ".9em",
                height: "fit-content",
              }}
              onClick={() => {
                handleApply(filter, index);
                setView(false);
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}